import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logo } from '../../constant/Util';

function AdminHeader({ menu, openSideBar }) {
    const navigate = useNavigate();
    const logout = () => {
        sessionStorage.clear();
        navigate('/');
    }
    return (
        <div className='header'>
            <nav className="navbar">
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    {
                        !menu ?
                            <div className={`${menu ? "adminHeader" : ""}`} onClick={openSideBar}>
                                <svg className='me-3' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={13} role='button'>
                                    <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                                </svg>
                            </div> :
                            <div></div>
                    }
                    {logo}
                    <div className='bg-danger p-1 rounded' role='button' onClick={logout}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={25} fill='white' className='p-1'>
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
                        </svg>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default AdminHeader