import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import userProfile from '../assets/images/user-profile.png';
import { getAPI } from '../apiServices/ApiService';
import { logo,swalMsg } from '../constant/Util';
import { playlistData } from '../layouts/DefaultLayout';
import logoicon from '../assets/images/logo-icon.png';

function Header({ OpenMenu }) {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [alert, setAlert] = useState(false);
  const [language, setLanguage] = useState([]);
  const webSearchContent = useContext(playlistData)
  const [selectedLanguage, setSelectedLanguage] = useState({
    id: webSearchContent?.language?.id || '',
    name: webSearchContent?.language?.name || '',
  }
  );
  const [search, setSearch] = useState();
  const [searchCopy,setSearchCopy] = useState();

  const getCurrentUserDetails = () => {
    getAPI(`/admin/currentuser/`).then((res) => {
      sessionStorage.removeItem("profile_changed")
      setUserData(res?.data?.data);
    }).catch((err) => {
      console.log(err);
    })
  }
  const getLanguage = () => {
    getAPI('/language/?sort=true&limit=0').then((res) => {
      setLanguage(res?.data?.data);
    }).catch((err) => {
      console.log(err);
    })
  };
  useEffect(() => {
    const handleUserUpdated = (e) => {
      if (sessionStorage.getItem("token")) {
        getCurrentUserDetails();
      }
    };
    window.addEventListener("userUpdated", handleUserUpdated);
    return () => {
      window.removeEventListener("userUpdated", handleUserUpdated);
    };
  }, []);

  useEffect(() => {
    sessionStorage.getItem("token") && getCurrentUserDetails();
    getLanguage();
    // eslint-disable-next-line
  }, []);

  const getAvatar = (fullname) => {
    if (!fullname) return '';

    const initials = fullname.split(' ').map(name => name[0]).join('');

    // Create canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const size = 60; // Size of the avatar
    canvas.width = size;
    canvas.height = size;

    // Draw background
    context.fillStyle = '#eb2163'; // Background color
    context.fillRect(0, 0, size, size);

    // Draw initials
    context.fillStyle = '#ffffff'; // Text color
    context.font = 'bold 30px Arial'; // Font size and style
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(initials, size / 2, size / 2);

    // Get the data URL of the image
    return canvas.toDataURL();
  }

  const handleLanguageChange = (languageId, languageName) => {

    if (languageId) {
      setSelectedLanguage({ id: languageId, name: languageName });
      webSearchContent?.setLanguage({ id: languageId, name: languageName });
     } else {
      setSelectedLanguage({
        id: '', name:""
      });
      webSearchContent?.setLanguage({ id: '', name: '' });
     }
  }

  const avatarSrc = userData?.fullname ? getAvatar(userData?.fullname) : '';

  useEffect(() => {
    if (alert) {
      swalMsg();
      setAlert(false)
    }
  }, [alert])
  
  const isMobile = window.innerWidth < 768;

const handleSubmit=(e)=>{
  e.preventDefault();
  // if(searchCopy!==search){
  //   console.log(search,searchCopy);
  //   const event = new Event('searchUpdated');
  //   window.dispatchEvent(event);
  //   setSearchCopy(search)
  // }
  webSearchContent?.setSearch(search);
  search && navigate('/search')
}
  useEffect(() => {
    if(!search){
      webSearchContent?.setSearch('')
      // navigate('/home')
    }
    // eslint-disable-next-line
  },[search])
  return (
    <div className='header'>
      {
        isMobile ?
          <>
            <div className='p-2'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <svg className='me-3' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={17} onClick={OpenMenu} role='button'>
                    <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                  </svg>
                  <div className='d-flex align-items-center' style={{ fontFamily: 'auto', fontSize: 'medium' }} >
                    <img src={logoicon} alt="Logo" width="30" height="30" className="align-text-center me-1" />
                    DB Spotify
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-end me-1'>
                  <div className='dropdown'>
                    <div className='dropdown-toggle-none p-0' type='button' id='languageDropdown' data-bs-toggle='dropdown' aria-expanded='false'>
                      {
                        selectedLanguage.id ?
                          <span className='bg-secondary text-white p-1 px-2 rounded-pill'>{selectedLanguage?.name}</span> : <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={22} fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855q-.215.403-.395.872c.705.157 1.472.257 2.282.287zM4.249 3.539q.214-.577.481-1.078a7 7 0 0 1 .597-.933A7 7 0 0 0 3.051 3.05q.544.277 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9 9 0 0 1-1.565-.667A6.96 6.96 0 0 0 1.018 7.5zm1.4-2.741a12.3 12.3 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332M8.5 5.09V7.5h2.99a12.3 12.3 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.6 13.6 0 0 1 7.5 10.91V8.5zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741zm-3.282 3.696q.18.469.395.872c.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a7 7 0 0 1-.598-.933 9 9 0 0 1-.481-1.079 8.4 8.4 0 0 0-1.198.49 7 7 0 0 0 2.276 1.522zm-1.383-2.964A13.4 13.4 0 0 1 3.508 8.5h-2.49a6.96 6.96 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667m6.728 2.964a7 7 0 0 0 2.275-1.521 8.4 8.4 0 0 0-1.197-.49 9 9 0 0 1-.481 1.078 7 7 0 0 1-.597.933M8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855q.216-.403.395-.872A12.6 12.6 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.96 6.96 0 0 0 14.982 8.5h-2.49a13.4 13.4 0 0 1-.437 3.008M14.982 7.5a6.96 6.96 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008zM11.27 2.461q.266.502.482 1.078a8.4 8.4 0 0 0 1.196-.49 7 7 0 0 0-2.275-1.52c.218.283.418.597.597.932m-.488 1.343a8 8 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
    </svg>
                          </span>
                      }
                    </div>
                    {selectedLanguage?.id && <span className="clear-language bg-danger rounded-circle" onClick={() => handleLanguageChange()}><i className="fa-solid fa-xmark text-white"></i></span>}
                    <ul className='dropdown-menu' aria-labelledby='languageDropdown'>
                      {
                        language?.map((item) => (
                          <button key={item?.id} className='btn btn-sm dropdown-item' onClick={() => handleLanguageChange(item?.id, item.name)} value={item?.id}>{item?.name}</button>
                        ))
                      }
                    </ul>
                  </div>
                  {
                    sessionStorage.getItem('token') ?
                      <Link className="text-decoration-none" to='/upload' title='Upload Video/Audio'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='mx-3' width={22} role='button'>
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                        </svg>
                      </Link> :
                      <span className='d-flex align-items-center' title='Sign in to upload media'>
                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setAlert(true)} viewBox="0 0 512 512" className='mx-3' width={22} role='button'>
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                        </svg>
                      </span>
                  }
                  {
                    sessionStorage.getItem('token') ?
                      <div className="">
                        <Link className="" to={`/profile/${userData?.user_id}`}  >
                          {
                            userData?.profile ?
                              <img src={`${userData?.profile}?random=${Math.random().toFixed(2)}`} alt="user" width={40} height={40} style={{ borderRadius: '50%' }} /> :
                              avatarSrc ?
                                <img src={avatarSrc} alt="user" width={20} style={{ borderRadius: '50%' }} />
                                : <img src={userProfile} alt="user" width={20} />
                          }
                        </Link>
                      </div>
                      :

                      <Link className="btn btn-outline-danger btn-sm" to='/login'>Sign In</Link>
                  }
                </div>
              </div>
              <form className="input-group globalSearch mt-3"
                onSubmit={handleSubmit}
              >
                <input type="text" className="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" value={search || ''} onChange={(e) => setSearch(e?.target?.value)} />
                <div className="input-group-append">
                  <button type='button' className={'btn-close' + (search ? ' clear-search' : ' d-none')} style={{ zIndex: 99 }} onClick={() => { setSearch(''); webSearchContent?.setSearch(''); setSearchCopy() }}></button>
                  <button className="btn px-3" type="submit" id="button-addon2" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={16}>
                      <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </>
          :
          <nav className="navbar">
            <div className="container-fluid row">
              <div className='col-3 d-flex align-items-center justify-content-start'>
                <svg className='me-3' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={20} onClick={OpenMenu} role='button'>
                  <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                </svg>
                {logo}
              </div>
              <div className="col-3 mx-auto mt-2 col-lg-5">
                <form className="input-group globalSearch"
                  onSubmit={handleSubmit}
                >
                  <input type="text" className="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" value={search || ''} onChange={(e) => setSearch(e?.target?.value)} />
                  <div className="input-group-append">
                    <button type='button' className={'btn-close' + (search ? ' clear-search' : ' d-none')} style={{ zIndex: 99 }} onClick={() => { setSearch(''); webSearchContent?.setSearch('');setSearchCopy() }}></button>
                    <button className="btn px-3" type="submit" id="button-addon2" >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={16}>
                        <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
              <div className='col-3 d-flex align-items-center justify-content-end'>
                <div className='dropdown me-2'>
                  <div className='dropdown-toggle-none p-0' type='button' id='languageDropdown' data-bs-toggle='dropdown' aria-expanded='false'>
                    {
                      selectedLanguage.id ?
                        <span className='bg-secondary text-white p-1 px-2 rounded-pill'>{selectedLanguage?.name}</span> : <span><svg xmlns="http://www.w3.org/2000/svg" width={22} fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855q-.215.403-.395.872c.705.157 1.472.257 2.282.287zM4.249 3.539q.214-.577.481-1.078a7 7 0 0 1 .597-.933A7 7 0 0 0 3.051 3.05q.544.277 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9 9 0 0 1-1.565-.667A6.96 6.96 0 0 0 1.018 7.5zm1.4-2.741a12.3 12.3 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332M8.5 5.09V7.5h2.99a12.3 12.3 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.6 13.6 0 0 1 7.5 10.91V8.5zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741zm-3.282 3.696q.18.469.395.872c.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a7 7 0 0 1-.598-.933 9 9 0 0 1-.481-1.079 8.4 8.4 0 0 0-1.198.49 7 7 0 0 0 2.276 1.522zm-1.383-2.964A13.4 13.4 0 0 1 3.508 8.5h-2.49a6.96 6.96 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667m6.728 2.964a7 7 0 0 0 2.275-1.521 8.4 8.4 0 0 0-1.197-.49 9 9 0 0 1-.481 1.078 7 7 0 0 1-.597.933M8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855q.216-.403.395-.872A12.6 12.6 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.96 6.96 0 0 0 14.982 8.5h-2.49a13.4 13.4 0 0 1-.437 3.008M14.982 7.5a6.96 6.96 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008zM11.27 2.461q.266.502.482 1.078a8.4 8.4 0 0 0 1.196-.49 7 7 0 0 0-2.275-1.52c.218.283.418.597.597.932m-.488 1.343a8 8 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                      </svg></span>
                    }
                  </div>
                  {selectedLanguage?.id && <span className="clear-language bg-danger rounded-circle" onClick={() => handleLanguageChange()}><i className="fa-solid fa-xmark text-light"></i></span>}
                  <ul className='dropdown-menu' aria-labelledby='languageDropdown'>
                    {
                      language?.map((item) => (
                        <button key={item?.id} className='btn btn-sm dropdown-item' onClick={() => handleLanguageChange(item?.id, item.name)} value={item?.id}>{item?.name}</button>
                      ))
                    }
                  </ul>
                </div>
                {
                  sessionStorage.getItem('token') ?
                    <Link className="text-decoration-none" to='/upload' title='Upload Video/Audio'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='mx-3' width={30} role='button'>
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                      </svg>
                    </Link> :
                    <span className='d-flex align-items-center' title='Sign in to upload media'>
                      <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setAlert(true)} viewBox="0 0 512 512" className='mx-3' width={30} role='button'>
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                      </svg>
                    </span>
                }
                {
                  sessionStorage.getItem('token') ?
                    <div className="">
                      <Link className="" to={`/profile/${userData?.user_id}`}  >
                        {
                          userData?.profile ?
                            <img src={`${userData?.profile}?random=${Math.random().toFixed(2)}`} alt="user" width={40} height={40} style={{ borderRadius: '50%' }} /> :
                            avatarSrc ?
                              <img src={avatarSrc} alt="user" width={40} style={{ borderRadius: '50%' }} />
                              : <img src={userProfile} alt="user" width={40} />
                        }
                      </Link>
                    </div>
                    :

                    <Link className="btn btn-outline-danger btn-sm" to='/login'>Sign In</Link>
                }
              </div>
            </div>
          </nav>
      }
    </div>
  )
}

export default Header