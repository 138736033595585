import React, { useState, useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom';
// user components
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import AppContent from './AppContent';

// Admin components
import AdminHeader from '../admin/components/AdminHeader';
import AdminSidebar from '../admin/components/AdminSidebar';
import AdminContent from './AdminContent'
import Feedback from '../views/Feedback';

export const playlistData = createContext(null);
function DefaultLayout() {
    const [open, setOpen] = useState(true);
    const [menu, setmenu] = useState(true);
    const location = useLocation();
    const [playlistRecords, setPlaylistRecords] = useState([]);
    const [search, setSearch] = useState();
    const [language, setLanguage] = useState({ id: '', name: '' });
    const [viewHelp, setViewHelp] = useState(false);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        const handleMediaQueryChange = (e) => {
            if (e.matches) {
                setmenu(false);
                setOpen(false);
            } else {
                setmenu(true);
                setOpen(true);
            }
        };
        mediaQuery.addEventListener('change', handleMediaQueryChange);
        if (mediaQuery.matches) {
            setmenu(false);
            setOpen(false);
        }
        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);
    const handleCancel = () => {
        setViewHelp(false);
    }
    return (
        <div>
            {
                location.pathname.match('/admin') ?
                    <>
                        <AdminHeader menu={menu} openSideBar={() => { setmenu(!menu) }} />
                        <AdminSidebar menu={menu} openSideBar={() => { setmenu(!menu) }} />
                        <div className={`${menu ? "admin-content" : "admin-content-all"} p-2`}>
                            <AdminContent />
                        </div>
                    </>
                    :
                    <>
                        <playlistData.Provider value={{ playlistRecords, setPlaylistRecords, search, setSearch, language, setLanguage }}>
                            <Header OpenMenu={() => setOpen(!open)} />
                            <div>
                                <div className={open ? 'sidebar open' : 'sidebar'} style={{marginBottom: sessionStorage.getItem('token') ? '35px':'0px'}}>
                                    <Sidebar handleMenu={() => setOpen(!open)}/>
                                </div>
                                <div className={open ? 'content open' : 'content'}>
                                    <AppContent />
                                </div>
                                {
                                    viewHelp ?
                                        <div className='helpView' style={{bottom: playlistRecords.length > 0 ? '95px' : '20px'}}><Feedback handleCancel={handleCancel} /></div>
                                        :
                                        <button type="button"  style={{bottom: playlistRecords.length > 0 ? '95px' : '20px'}} title='Help' className='btn btn-danger btn-sm rounded-4 helpBtn' onClick={() => { setViewHelp(true) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-dots" viewBox="0 0 16 16">
                                            <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                                            <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2" />
                                        </svg> Help</button>
                                }

                            </div>
                            <Footer />
                        </playlistData.Provider>
                    </>
            }
        </div>
    )
}

export default DefaultLayout