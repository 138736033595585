import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { addUpdateAPI } from '../apiServices/ApiService';
import bg1 from '../assets/videos/login_bg.mp4';
import { logo } from '../constant/Util';

const schema = yup.object().shape({
  email: yup.string().matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    'Invalid email'
  ).required('Email is required'),
  password: yup.string().required('Password is required'),
});
function Login() {
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = useState('')
  const [view, setView] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('username', data?.email);
    formData.append('password', data?.password);
    addUpdateAPI('POST', '/admin/login/', formData).then((res) => {
      if (res.data.status) {
        if (res.data.admin) {
          navigate('/admin/home');
        } else {
          navigate('/home');
        }
        sessionStorage.setItem('token', res.data.access_token);
      } else {
        setErrorMsg(res.data.detail);
      }

    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <div className="container-fluid1 d-flex justify-content-center align-items-center vh-100 bg-image">
      <div className='video-container'><video src={bg1} autoPlay muted loop className='bg-video' /></div>
      <div className="login-form bg-light p-5 rounded bg-transparent position-absolute">
        <p className='text-danger text-center mt-0'>{errorMsg}</p>
        {logo}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-3 mt-2'>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Email"
                aria-label="Email"
                aria-describedby="Email"
                {...register('email')}
              />
              <span className="input-group-text" id="Email">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
              </span>
            </div>
            {errors.email && <div className="text-danger small">{errors.email.message}</div>}
          </div>
          <div className='mb-3'>
            <div className="input-group">
              <input
                type={view ? 'text' : 'password'}
                className="form-control form-control-sm"
                placeholder="Password"
                aria-label="Password"
                aria-describedby="Password"
                {...register('password')}
              />
              <span className="input-group-text" id="Password" onClick={() => setView(!view)} role="button">
                {view ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                  </svg>
                )}
              </span>
            </div>
            {errors.password && <div className="text-danger small">{errors.password.message}</div>}
          </div>
          <div className="text-center">
            <Link className="btn btn-secondary rounded btn-sm " title='Back' to={'/home'}>Back</Link>
            <button type="submit" className="btn btn-danger rounded btn-sm ms-3" title="Login">Login</button>
          </div>
          <div className="text-center mt-2">
            Don't have an account? <Link className="text-decoration-none text-danger fw-bold" title="Register" to={'/register'}>Register now</Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login